import React from 'react'
import { useTranslation } from '../../../../services/translation'
import { Form, OverlayTrigger, Tooltip, Badge } from 'react-bootstrap'

const FieldDeadline = ({ formik, proposalType, settings }) => {
  const { t } = useTranslation()
  return (
    <>
      <Form.Label>
        {t('forms.prazo')}
        <OverlayTrigger
          placement='top'
          delay={{ show: 250, hide: 400 }}
          overlay={
            <Tooltip>
              {t('forms.prazotip')}
              {proposalType !== 'EXCLUSIVE' &&
                ` ${t(`forms.prazotip${proposalType}`, {
                  deadline: settings.raisingFundsDeadline,
                })}`}
            </Tooltip>
          }
        >
          <Badge pill bg='info' className='infotip'>
            ?
          </Badge>
        </OverlayTrigger>
      </Form.Label>
      <Form.Control
        {...formik.getFieldProps('deadline')}
        type='number'
        placeholder={t('forms.prazo')}
        min='1'
        max={settings.modelingDeadline}
        isInvalid={formik.touched.deadline && formik.errors.deadline}
        isValid={formik.touched.deadline && !formik.errors.deadline}
      />
      <Form.Control.Feedback type='invalid'>
        {formik.touched.deadline && formik.errors.deadline
          ? formik.errors.deadline
          : null}
      </Form.Control.Feedback>
    </>
  )
}

export default FieldDeadline
