import React from 'react'
import Router from 'next/router'
import ModalConfirm2 from '../ModalConfirm2'
import { useTranslation } from '../../services/translation'

export default function ModalModeler({ showModal, handleHideConfirm }) {
  const { t } = useTranslation()

  const goToAccount = () => {
    Router.push('/account')
  }

  const buttons = [
    {
      label: t('modal.modeladornaoaprovado.btn-confirm'),
      handleClick: goToAccount,
    },
    {
      className: 'bt-secundario',
      label: t('modal.modeladornaoaprovado.btn-cancel'),
      handleClick: handleHideConfirm,
    },
  ]

  return (
    <ModalConfirm2
      show={showModal}
      handleClose={handleHideConfirm}
      title={t('modal.modeladornaoaprovado.tit')}
      buttons={buttons}
    >
      <p>{t('modal.modeladornaoaprovado.txt1')}</p>
      <p>{t('modal.modeladornaoaprovado.txt2')}</p>
    </ModalConfirm2>
  )
}
