import React, { useState } from 'react'
import { useTranslation } from '../../../../services/translation'
import ModalConfirm2 from '../../../ModalConfirm2'
import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'
import { Alert } from 'react-bootstrap'

const DO_LIKE_MODEL = gql`
  mutation likeModel($modelId: ID!) {
    likeModel(modelId: $modelId) {
      id
    }
  }
`

const ModalLike = ({ show, handleClose, modelId, onLike }) => {
  const { t } = useTranslation()
  const [likeModel, { loading }] = useMutation(DO_LIKE_MODEL)
  const [generalErrors, setGeneralErrors] = useState('')

  const buttonsLike = [
    {
      label: t('botoes.interesso'),
      handleClick: () => {
        likeModel({
          variables: {
            modelId,
          },
        })
          .then(() => {
            onLike()
          })
          .catch(({ networkError, graphQLErrors }) => {
            let gError = ''
            if (networkError || (!networkError && !graphQLErrors))
              gError = t('login.failed')
            else {
              if (graphQLErrors.length) {
                graphQLErrors.map(({ state }) => {
                  Object.entries(state).forEach(entry => {
                    gError = entry[1][0]
                  })
                })
              }
            }
            setGeneralErrors(gError)
          })
      },
      loading,
      disabled: loading,
    },
    {
      className: 'bt-secundario',
      label: t('botoes.cancelar'),
      handleClick: handleClose,
    },
  ]

  return (
    <ModalConfirm2
      show={show}
      handleClose={handleClose}
      title={t('modal.confirmainteresse.tit')}
      buttons={buttonsLike}
    >
      <p>{t('modal.confirmainteresse.txt1')}</p>
      <p>{t('modal.confirmainteresse.txt2')}</p>
      {generalErrors && !loading && (
        <Alert variant='danger'>{generalErrors}</Alert>
      )}
    </ModalConfirm2>
  )
}

export default ModalLike
