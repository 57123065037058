export const getQuotaValue = (
  amount,
  qtd,
  { administrativeFee, processingFee, gatewayFixedFee }
) => {
  const price = Math.ceil((amount / qtd) * 100) / 100
  const priceWithFee = applyAdministrativeFee(price, administrativeFee)
  return {
    withoutFee: price.toFixed(2),
    raisingFunds: applyProcessingFee(
      priceWithFee,
      processingFee,
      gatewayFixedFee
    ).toFixed(2),
    modeling: applyModelingFee(
      priceWithFee,
      processingFee,
      gatewayFixedFee
    ).toFixed(2),
    finished: applyFinishedFee(
      priceWithFee,
      processingFee,
      gatewayFixedFee
    ).toFixed(2),
    finishedFinished: applyProcessingFee(
      priceWithFee,
      processingFee,
      gatewayFixedFee
    ).toFixed(2),
  }
}

const applyAdministrativeFee = (value, administrativeFee) => {
  return value * (1 + administrativeFee / 100)
}

const applyProcessingFee = (value, processingFee, gatewayFixedFee) => {
  const priceWithFixedFee = value + gatewayFixedFee
  const finalProcessingFee = priceWithFixedFee * processingFee + gatewayFixedFee

  return value + finalProcessingFee
}

const applyModelingFee = (value, processingFee, gatewayFixedFee) => {
  return applyProcessingFee(value * 1.33, processingFee, gatewayFixedFee)
}

const applyFinishedFee = (value, processingFee, gatewayFixedFee) => {
  return applyProcessingFee(value * 2, processingFee, gatewayFixedFee)
}
