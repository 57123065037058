import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { useTranslation } from '../../services/translation'
import ModalNotLoggedIn from '../ModalNotLoggedIn'
import { useAuth } from '../../contexts/auth'
import ModalCheckout from '../ModalCheckout'
import { useRouter } from 'next/router'

function ModalParticipa({ model, onRefreshModel, initOpen = false }) {
  const { user } = useAuth()
  const [showModal, setShowModal] = useState(initOpen ? 2 : 0)
  const { t } = useTranslation()
  const handleClose = () => setShowModal(0)
  const handleShow = () => {
    if (!user) return setShowModal(1)
    setShowModal(2)
  }
  const { asPath } = useRouter()
  const arrPath = asPath.split('/')
  const url = arrPath[1]

  return (
    <>
      <ModalNotLoggedIn
        show={showModal === 1}
        handleClose={handleClose}
        onSuccess={() => setShowModal(2)}
      />
      <ModalCheckout
        show={showModal === 2}
        handleClose={handleClose}
        model={model}
        onRefreshModel={onRefreshModel}
        quotaPriceWithFee={model.quotaPriceWithFee}
        qtyDisabled={model.status === 'FINISHED'}
      />
      <div
        className={`d-flex ${
          url !== 'm' && url !== 'p' ? 'justify-content-center' : ''
        }`}
      >
        <Button
          className={`btn-oneclick btn-card rounded-pill bt-azul ${
            url !== 'm' && url !== 'p' ? 'btn-card-n-pos' : ''
          }`}
          onClick={handleShow}
        >
          {model.status !== 'FINISHED' && model.quotaPrice !== 0 && (
            <span className='px-2'>
              <i className='icone-onebuy' aria-hidden='true'></i>{' '}
              {t('itemcard.btparticipar')}
            </span>
          )}
          {model.status === 'FINISHED' && model.quotaPrice !== 0 && (
            <span className='px-2'>
              <i className='icone-onebuy' aria-hidden='true'></i>{' '}
              {t('botoes.oneclick')}{' '}
            </span>
          )}
          {model.quotaPrice === 0 && (
            <i className='fa fa-cloud-download fs-5' aria-hidden='true'></i>
          )}
        </Button>
        {/* <OverlayTrigger
          placement='top'
          delay={{ show: 250, hide: 400 }}
          overlay={<Tooltip>Add to cart</Tooltip>}
        >
          <Button
            className={`btn-card rounded-circle bt-azul ${
              url !== 'm' && url !== 'p' ? 'btn-card-n-pos' : ''
            }`}
          >
            <i className='fa fa-shopping-cart fs-6 p-1' aria-hidden='true'></i>
          </Button>
        </OverlayTrigger>
        <OverlayTrigger
          placement='top'
          delay={{ show: 250, hide: 400 }}
          overlay={<Tooltip>Add to wishlist</Tooltip>}
        >
          <Button
            className={`btn-card rounded-circle bt-azul ${
              url !== 'm' && url !== 'p' ? 'btn-card-n-pos' : ''
            }`}
          >
            <i className='fa fa-heart fs-6 p-1' aria-hidden='true'></i>
          </Button>
        </OverlayTrigger> */}
      </div>
    </>
  )
}

export default ModalParticipa
