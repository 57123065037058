import React, { useEffect, useState } from 'react'
import ModalNotLoggedIn from '../../../ModalNotLoggedIn'
import ModalLike from '../ModalLike'
import ModalDislike from '../ModalDislike'
import { useAuth } from '../../../../contexts/auth'
import { Button } from 'react-bootstrap'
import { useRouter } from 'next/router'

const ModalLikeDislike = ({ modelId, modelLikes, onLikeDislike }) => {
  const [showModal, setShowModal] = useState(0)
  const { user } = useAuth()
  const userLiked = user ? modelLikes.find(like => like.id === user.id) : false

  const handleShow = () => {
    if (!user) return setShowModal(1)
    setShowModal(userLiked ? 3 : 2)
  }
  const handleClose = () => setShowModal(0)
  const handleLikeDislike = () => {
    handleClose()
    onLikeDislike()
  }
  const [shouldShow, setShouldShow] = useState(false)
  useEffect(() => {
    if (shouldShow && user) {
      setShouldShow(false)
      handleShow()
    }
  }, [shouldShow, user])
  const onLoginSuccess = () => {
    setShouldShow(true)
  }

  const { asPath } = useRouter()
  const arrPath = asPath.split('/')
  const url = arrPath[1]

  return (
    <>
      <ModalNotLoggedIn
        show={showModal === 1}
        handleClose={handleClose}
        onSuccess={onLoginSuccess}
      />
      <ModalLike
        show={showModal === 2}
        handleClose={handleClose}
        modelId={modelId}
        onLike={handleLikeDislike}
      />
      <ModalDislike
        show={showModal === 3}
        handleClose={handleClose}
        modelId={modelId}
        onDislike={handleLikeDislike}
      />
      <Button
        onClick={handleShow}
        className={`btn-card rounded-circle ${
          userLiked ? 'bt-amarelo text-white shadow' : 'bt-azul'
        } ${url !== 'm' && url !== 'p' && 'btn-card-n-pos'}`}
      >
        <i className='icone-like' aria-hidden='true'></i>
      </Button>
    </>
  )
}

export default ModalLikeDislike
