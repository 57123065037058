import React, { useState, useEffect } from 'react'
import ModalConfirm2 from '../../../ModalConfirm2'
import { useTranslation } from '../../../../services/translation'
import { Form, Row, Col, Alert, Button, Spinner } from 'react-bootstrap'
import CurrencyFormat from 'react-currency-format'
import { gql } from 'apollo-boost'
import { useQuery, useMutation } from '@apollo/react-hooks'
import ModalModeler from '../../../ModalModeler'
import ModalNotLoggedIn from '../../../ModalNotLoggedIn'
import { getQuotaValue } from '../../../../utils/quota.util'
import { useFormik, validateYupSchema, yupToFormErrors } from 'formik'
import * as Yup from 'yup'
import { useAuth } from '../../../../contexts/auth'
import { toRealFormat } from '../../../../utils/currency-format.util'
import Price from '../../../Price'
import ErrorFocus from '../../../ErrorFocus'
import FieldDeadline from '../FieldDeadline'
import CollapseLink from '../../../../components/CollapseLink'
import { useLocales } from '../../../../contexts/locales'
import ModalLikeDislike from '../ModalLikeDislike'
import { useRouter } from 'next/router'

const GET_USER = gql`
  query getUser {
    getUser {
      approvedModeler
    }
  }
`
const GET_SETTINGS = gql`
  query getSettings {
    getSettings {
      modelingDeadline
      maxModelAmount
      maxTotalQuotas
      maxQuotaPrice
      minQuotaPrice
      administrativeFee
      processingFee
      gatewayFixedFee
      dollarQuote
      raisingFundsDeadline
    }
    getExclusivityPeriods {
      id
      months
    }
  }
`

const DO_CREATE_PROPOSAL = gql`
  mutation createModelProposal(
    $modelId: ID!
    $deadline: Int!
    $crowdfundingProposal: CrowdfundingProposalInput
    $exclusivityProposals: [ExclusivityProposalInput!]
  ) {
    createModelProposal(
      modelId: $modelId
      deadline: $deadline
      crowdfundingProposal: $crowdfundingProposal
      exclusivityProposals: $exclusivityProposals
    )
  }
`

const ModalProposal = ({
  modelId,
  modelLikes,
  modelProposals,
  modelOrganizer,
  onUpdateModel,
  proposalType,
}) => {
  const { t } = useTranslation()
  const { currency } = useLocales()
  const { refetch } = useQuery(GET_USER, {
    fetchPolicy: 'network-only',
    skip: true,
  })
  const { data: settings, loading } = useQuery(GET_SETTINGS, {
    fetchPolicy: 'network-only',
  })
  const { user } = useAuth()

  const [createModelProposal] = useMutation(DO_CREATE_PROPOSAL)
  const [generalErrors, setGeneralErrors] = useState('')
  const [showModal, setShowModal] = useState(0)

  const maxAmount = settings ? settings.getSettings.maxModelAmount : 0
  const maxTotalQuotas = settings ? settings.getSettings.maxTotalQuotas : 0
  const maxDeadline = settings ? settings.getSettings.modelingDeadline : 0
  const minQuotaPriceDollar = settings
    ? settings.getSettings.minQuotaPrice / settings.getSettings.dollarQuote
    : 0
  const maxQuotaPriceDollar = settings
    ? settings.getSettings.maxQuotaPrice / settings.getSettings.dollarQuote
    : 0

  const formik = useFormik({
    initialValues: {
      deadline: '',
      crowdfundingProposal: {
        amount: undefined,
        totalQuotas: '',
      },
      exclusivityProposals: [],
    },
    validate: value => {
      const proposalWithAmount = value.exclusivityProposals
        ? value.exclusivityProposals.find(proposal => !!proposal.amount)
        : undefined
      const schema = Yup.object({
        crowdfundingProposal:
          proposalType === 'EXCLUSIVE'
            ? Yup.object().notRequired()
            : Yup.object().shape(
                {
                  amount: Yup.number()
                    .moreThan(0, ({ more }) =>
                      t('forms.precomin', { min: more })
                    )
                    .max(maxAmount, ({ max }) => t('forms.precomax', { max }))
                    .when('totalQuotas', {
                      is: totalQuotas =>
                        proposalType === 'CROWDFUNDING' || !!totalQuotas,
                      then: Yup.number().required(t('forms.precovali')),
                    }),
                  totalQuotas: Yup.number()
                    .moreThan(0, ({ more }) =>
                      t('forms.cotasmin', { min: more })
                    )
                    .max(maxTotalQuotas, ({ max }) =>
                      t('forms.cotasmax', { max })
                    )
                    .when('amount', {
                      is: amount => proposalType === 'CROWDFUNDING' || !!amount,
                      then: Yup.number().required(t('forms.cotasvali')),
                    }),
                },
                ['amount', 'totalQuotas']
              ),
        exclusivityProposals:
          proposalType === 'CROWDFUNDING'
            ? Yup.array().notRequired()
            : Yup.array().of(
                Yup.object().shape({
                  amount:
                    proposalType === 'EXCLUSIVE' || proposalWithAmount
                      ? Yup.number()
                          .moreThan(0, ({ more }) =>
                            t('forms.precomin', { min: more })
                          )
                          .required(t('forms.precovali'))
                      : Yup.number().notRequired(),
                })
              ),
        deadline: Yup.number()
          .moreThan(0, ({ more }) => t('forms.prazomin', { min: more }))
          .max(maxDeadline, ({ max }) => t('forms.prazomax', { max }))
          .required(t('forms.prazovali')),
      })
      try {
        validateYupSchema(value, schema, true)
      } catch (err) {
        return yupToFormErrors(err)
      }
      return {}
    },
    onSubmit: (values, { setSubmitting, setFieldError }) => {
      setGeneralErrors('')
      if (values.crowdfundingProposal.amount) {
        const { withoutFee: quotaValue } = getQuotaValue(
          values.crowdfundingProposal.amount,
          values.crowdfundingProposal.totalQuotas,
          settings.getSettings
        )
        if (isNaN(quotaValue) || quotaValue < 0) {
          setFieldError('quotaPrice', t('forms.cotasprecoinvalido'))
          return setSubmitting(false)
        }
        if (quotaValue < settings.getSettings.minQuotaPrice) {
          setFieldError(
            'quotaPrice',
            t('forms.cotasprecomin', {
              min: settings.getSettings.minQuotaPrice,
              minDollar: minQuotaPriceDollar.toFixed(2),
            })
          )
          return setSubmitting(false)
        }
        if (quotaValue > settings.getSettings.maxQuotaPrice) {
          setFieldError(
            'quotaPrice',
            t('forms.cotasprecomax', {
              max: settings.getSettings.maxQuotaPrice,
              maxDollar: maxQuotaPriceDollar.toFixed(2),
            })
          )
          return setSubmitting(false)
        }
      }
      if (
        !values.crowdfundingProposal.amount &&
        !values.exclusivityProposals[0].amount
      ) {
        setGeneralErrors(t('forms.crowdfundingOrExclusiveRequired'))
        return setSubmitting(false)
      }
      const crowdfundingProposal =
        proposalType !== 'EXCLUSIVE' &&
        values.crowdfundingProposal.amount != null
          ? {
              amount: parseFloat(values.crowdfundingProposal.amount),
              totalQuotas: parseInt(values.crowdfundingProposal.totalQuotas),
            }
          : undefined
      const exclusivityProposals =
        proposalType !== 'CROWDFUNDING' &&
        values.exclusivityProposals[0].amount != null
          ? values.exclusivityProposals.map(period => ({
              periodId: period.periodId,
              amount: parseFloat(period.amount),
            }))
          : undefined
      return createModelProposal({
        variables: {
          modelId,
          deadline: parseInt(values.deadline),
          crowdfundingProposal,
          exclusivityProposals,
        },
      })
        .then(() => {
          setShowModal(4)
        })
        .catch(({ networkError, graphQLErrors }) => {
          let gError = ''
          if (networkError || (!networkError && !graphQLErrors))
            gError = t('modal.proposta.failed')
          else {
            if (graphQLErrors.length) {
              graphQLErrors.map(({ state }) => {
                Object.entries(state).forEach(entry => {
                  if (entry[0] === '') gError = entry[1][0]
                  else setFieldError(entry[0], entry[1][0])
                })
              })
            }
          }
          setGeneralErrors(gError)
        })
        .finally(() => setSubmitting(false))
    },
  })

  const { asPath } = useRouter()
  const arrPath = asPath.split('/')
  const url = arrPath[1]

  useEffect(() => {
    if (settings) {
      const exclusivityProposals = settings.getExclusivityPeriods.map(
        period => ({
          periodId: period.id,
          amount: undefined,
          months: period.months,
        })
      )
      formik.setFieldValue('exclusivityProposals', exclusivityProposals)
    }
  }, [settings])

  const handleClose = () => setShowModal(0)
  const handleCloseSucesso = () => {
    handleClose()
    onUpdateModel()
  }
  const handleShow = async () => {
    if (!user) return setShowModal(1)
    if (user.id === modelOrganizer.id) return setShowModal(6)
    const userSubmittedProposal = modelProposals.find(
      prop => prop.modeler.id === user.id
    )
    if (userSubmittedProposal) return setShowModal(5)
    const { data } = await refetch()
    if (!data.getUser.approvedModeler) return setShowModal(2)
    setShowModal(3)
  }
  const [shouldShow, setShouldShow] = useState(false)
  useEffect(() => {
    if (shouldShow && user) {
      setShouldShow(false)
      handleShow()
    }
  }, [shouldShow, user])
  const onLoginSuccess = () => {
    setShouldShow(true)
  }

  const buttons = [
    {
      label: t('botoes.enviar'),
      handleClick: () => formik.handleSubmit(),
      loading: formik.isValidating || formik.isSubmitting,
      disabled: formik.isValidating || formik.isSubmitting,
    },
    {
      className: 'bt-secundario',
      label: t('botoes.cancelar'),
      handleClick: handleClose,
    },
  ]

  if (loading) {
    return (
      <div className='text-center'>
        <Spinner animation='border' />
      </div>
    )
  }

  let quotaValues
  if (
    formik.values.crowdfundingProposal.amount &&
    formik.values.crowdfundingProposal.totalQuotas
  ) {
    quotaValues = getQuotaValue(
      formik.values.crowdfundingProposal.amount,
      formik.values.crowdfundingProposal.totalQuotas,
      settings.getSettings
    )
  }

  return (
    <>
      <ModalNotLoggedIn
        show={showModal === 1}
        handleClose={handleClose}
        onSuccess={onLoginSuccess}
      />
      <ModalModeler
        showModal={showModal === 2}
        handleHideConfirm={handleClose}
      />
      <ModalConfirm2
        show={showModal === 3}
        handleClose={handleClose}
        title={t('modal.proposta.tit')}
        buttons={buttons}
      >
        <p className='alerta-modelador'>{t('modal.proposta.txt2')}</p>
        {generalErrors && <Alert variant='danger'>{generalErrors}</Alert>}
        <Form noValidate onSubmit={formik.handleSubmit}>
          {proposalType === 'BOTH' && (
            <>
              <p>{t('exclusivo.titboth')}</p>
              <Row>
                <Form.Group
                  as={Col}
                  xs={12}
                  sm={12}
                  md={12}
                  lg={4}
                  className='mb-3'
                  controlId='formGridDeadline'
                >
                  <FieldDeadline
                    formik={formik}
                    proposalType={proposalType}
                    settings={settings.getSettings}
                  />
                </Form.Group>
              </Row>
              <hr className='mt-0' />
            </>
          )}
          <Row>
            {proposalType !== 'EXCLUSIVE' && (
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={proposalType !== 'BOTH' ? 12 : 6}
              >
                <h5>{t('exclusivo.titcrwf')}</h5>
                <p className={proposalType === 'BOTH' ? 'small' : ''}>
                  {t('modal.proposta.txt1')}
                </p>
                <Row>
                  <Form.Group
                    as={Col}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={proposalType !== 'BOTH' ? 4 : 6}
                    controlId='formGridAmount'
                    className='mb-3'
                  >
                    <Form.Label>{t('forms.precotit')}</Form.Label>
                    <CurrencyFormat
                      name='crowdfundingProposal.amount'
                      value={formik.values.crowdfundingProposal.amount}
                      onBlur={formik.handleBlur}
                      onValueChange={values =>
                        formik.setFieldValue(
                          'crowdfundingProposal.amount',
                          values.value
                        )
                      }
                      type='tel'
                      thousandSeparator='.'
                      decimalScale={2}
                      decimalSeparator=','
                      fixedDecimalScale
                      placeholder={t('forms.preco')}
                      className={
                        'form-control' +
                        (formik.touched.crowdfundingProposal &&
                        formik.errors.crowdfundingProposal &&
                        formik.touched.crowdfundingProposal.amount &&
                        formik.errors.crowdfundingProposal.amount
                          ? ' is-invalid'
                          : '') +
                        (formik.touched.crowdfundingProposal &&
                        formik.errors.crowdfundingProposal &&
                        formik.touched.crowdfundingProposal.amount &&
                        !formik.errors.crowdfundingProposal.amount
                          ? ' is-valid'
                          : '')
                      }
                      allowNegative={false}
                      isNumericString
                      id='formGridAmount'
                    />
                    <Form.Control.Feedback type='invalid'>
                      {formik.touched.crowdfundingProposal &&
                      formik.errors.crowdfundingProposal &&
                      formik.touched.crowdfundingProposal.amount &&
                      formik.errors.crowdfundingProposal.amount
                        ? formik.errors.crowdfundingProposal.amount
                        : null}
                    </Form.Control.Feedback>
                    {currency !== 'BRL' && (
                      <Form.Text>
                        {t('forms.precoConvert')}
                        {formik.values.crowdfundingProposal.amount
                          ? (
                              formik.values.crowdfundingProposal.amount /
                              settings.getSettings.dollarQuote
                            ).toFixed(2)
                          : 0.0}
                      </Form.Text>
                    )}
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={proposalType !== 'BOTH' ? 4 : 6}
                    controlId='formGridTotalQuotas'
                    className='mb-3'
                  >
                    <Form.Label>{t('forms.cotastit')}</Form.Label>
                    <Form.Control
                      {...formik.getFieldProps(
                        'crowdfundingProposal.totalQuotas'
                      )}
                      type='number'
                      placeholder={t('forms.cotas')}
                      isInvalid={
                        formik.touched.crowdfundingProposal &&
                        formik.errors.crowdfundingProposal &&
                        formik.touched.crowdfundingProposal.totalQuotas &&
                        formik.errors.crowdfundingProposal.totalQuotas
                      }
                      isValid={
                        formik.touched.crowdfundingProposal &&
                        formik.errors.crowdfundingProposal &&
                        formik.touched.crowdfundingProposal.totalQuotas &&
                        !formik.errors.crowdfundingProposal.totalQuotas
                      }
                      min='1'
                      max={settings.getSettings.maxTotalQuotas}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {formik.touched.crowdfundingProposal &&
                      formik.errors.crowdfundingProposal &&
                      formik.touched.crowdfundingProposal.totalQuotas &&
                      formik.errors.crowdfundingProposal.totalQuotas
                        ? formik.errors.crowdfundingProposal.totalQuotas
                        : null}
                    </Form.Control.Feedback>
                  </Form.Group>
                  {proposalType !== 'BOTH' && (
                    <Form.Group
                      as={Col}
                      xs={12}
                      sm={12}
                      md={12}
                      lg={4}
                      className='mb-3'
                      controlId='formGridDeadline'
                    >
                      <FieldDeadline
                        formik={formik}
                        proposalType={proposalType}
                        settings={settings.getSettings}
                      />
                    </Form.Group>
                  )}
                </Row>
                {formik.values.crowdfundingProposal.amount &&
                  formik.values.crowdfundingProposal.totalQuotas && (
                    <>
                      <p>
                        <span className='txtdestaque'>
                          {t('modal.proposta.valorcota')}{' '}
                          {currency !== 'BRL' &&
                            `${t('moeda.valor')} ${toRealFormat(
                              quotaValues.withoutFee
                            )} (`}
                          <Price value={quotaValues.withoutFee} />
                          {currency !== 'BRL' && ')'}
                        </span>{' '}
                        ({t('modal.proposta.obstaxas1a')}{' '}
                        <b>
                          {t('moeda.valor')}
                          {toRealFormat(quotaValues.raisingFunds)}
                        </b>
                        {t('modal.proposta.obstaxas1b')} )<br />
                      </p>
                      <p>
                        <span className='txtobs'>
                          {t('modal.proposta.obstaxas2')}
                          <b>
                            {t('moeda.valor')}
                            {toRealFormat(quotaValues.withoutFee * 1.35)}
                          </b>
                          {t('modal.proposta.obstaxas3')}
                        </span>
                      </p>
                      {formik.errors.quotaPrice && (
                        <Alert variant='danger'>
                          {formik.errors.quotaPrice}
                        </Alert>
                      )}
                    </>
                  )}
              </Col>
            )}
            {proposalType !== 'CROWDFUNDING' && (
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={proposalType !== 'BOTH' ? 12 : 6}
              >
                <h5>{t('exclusivo.titexclusive')}</h5>
                <p className={proposalType === 'BOTH' ? 'small' : ''}>
                  {t('exclusivo.subtit')}
                </p>
                <Row>
                  {proposalType !== 'BOTH' && (
                    <Form.Group
                      as={Col}
                      xs={12}
                      sm={12}
                      md={12}
                      lg={3}
                      controlId='formGridDeadline'
                    >
                      <FieldDeadline
                        formik={formik}
                        proposalType={proposalType}
                        settings={settings.getSettings}
                      />
                    </Form.Group>
                  )}
                  {formik.values.exclusivityProposals.map((period, index) => (
                    <Form.Group
                      as={Col}
                      key={period.periodId}
                      xs={12}
                      sm={12}
                      md={12}
                      lg={proposalType !== 'BOTH' ? 3 : 4}
                      className='mb-3'
                      controlId={`formGridAmount${period.periodId}`}
                    >
                      <Form.Label>
                        {t('forms.precotitexclusivo' + period.months)}
                      </Form.Label>
                      <CurrencyFormat
                        name={`exclusivityProposals.${index}.amount`}
                        value={period.amount}
                        onValueChange={values =>
                          formik.setFieldValue(
                            `exclusivityProposals.${index}.amount`,
                            values.value
                          )
                        }
                        type='tel'
                        thousandSeparator='.'
                        decimalScale={2}
                        decimalSeparator=','
                        fixedDecimalScale
                        placeholder={t('forms.preco')}
                        className={
                          'form-control' +
                          (formik.touched.exclusivityProposals &&
                          formik.errors.exclusivityProposals &&
                          formik.touched.exclusivityProposals[index] &&
                          formik.errors.exclusivityProposals[index] &&
                          formik.touched.exclusivityProposals[index].amount &&
                          formik.errors.exclusivityProposals[index].amount
                            ? ' is-invalid'
                            : '') +
                          (formik.touched.exclusivityProposals &&
                          formik.errors.exclusivityProposals &&
                          formik.touched.exclusivityProposals[index] &&
                          formik.errors.exclusivityProposals[index] &&
                          formik.touched.exclusivityProposals[index].amount &&
                          !formik.errors.exclusivityProposals[index].amount
                            ? ' is-valid'
                            : '')
                        }
                        allowNegative={false}
                        isNumericString
                        id={`formGridAmount${period.periodId}`}
                      />
                      <Form.Control.Feedback type='invalid'>
                        {formik.touched.exclusivityProposals &&
                        formik.errors.exclusivityProposals &&
                        formik.touched.exclusivityProposals[index] &&
                        formik.errors.exclusivityProposals[index] &&
                        formik.touched.exclusivityProposals[index].amount &&
                        formik.errors.exclusivityProposals[index].amount
                          ? formik.errors.exclusivityProposals[index].amount
                          : null}
                      </Form.Control.Feedback>
                      {currency !== 'BRL' && (
                        <Form.Text>
                          {t('forms.precoConvert')}
                          {period.amount
                            ? (
                                period.amount / settings.getSettings.dollarQuote
                              ).toFixed(2)
                            : 0.0}
                        </Form.Text>
                      )}
                    </Form.Group>
                  ))}
                </Row>
                <ErrorFocus formikprm={formik} />
                <p className='exclusivealert'>
                  {t('exclusivo.exclusivealert')}
                </p>
                <CollapseLink
                  linkText={`(${t('exclusivo.link')})`}
                  linkClass='fs-xsmall'
                  bodyClass='fs-xsmall'
                >
                  {t('exclusivo.txt')}
                </CollapseLink>
              </Col>
            )}
          </Row>
        </Form>
      </ModalConfirm2>
      <ModalConfirm2
        show={showModal === 4}
        handleClose={handleCloseSucesso}
        title={t('modal.propostasucesso.tit')}
        buttons={[
          {
            label: t('modal.propostasucesso.button'),
            handleClick: handleCloseSucesso,
          },
        ]}
      >
        <p>{t('modal.propostasucesso.txt1')}</p>
      </ModalConfirm2>
      <ModalConfirm2
        show={showModal === 5}
        handleClose={handleClose}
        title={t('modal.propostajaenviada.tit')}
        buttons={[
          {
            label: t('modal.propostajaenviada.button'),
            handleClick: handleClose,
          },
        ]}
      >
        <p>{t('modal.propostajaenviada.txt1')}</p>
      </ModalConfirm2>
      <ModalConfirm2
        show={showModal === 6}
        handleClose={handleClose}
        title={t('modal.propostaeorganizador.tit')}
        buttons={[
          {
            label: t('modal.propostaeorganizador.button'),
            handleClick: handleClose,
          },
        ]}
      >
        <p>{t('modal.propostaeorganizador.txt1')}</p>
      </ModalConfirm2>
      <div
        className={`d-flex mt-3 ${
          url !== 'm' && url !== 'p' ? 'justify-content-center' : ''
        }`}
      >
        <Button
          className={`btn-paralel btn-card bt-azul ${
            url !== 'm' && url !== 'p' ? 'btn-card-n-pos' : ''
          }`}
          onClick={handleShow}
        >
          <span className='skew-fix'>{t('itemcard.btmodelar')}</span>
        </Button>
        <ModalLikeDislike
          modelId={modelId}
          modelLikes={modelLikes}
          onLikeDislike={onUpdateModel}
        />
      </div>
    </>
  )
}

export default ModalProposal
